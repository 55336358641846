import React from "react";
import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import styles from "./mightFit.module.scss";
import { useProductPageStore } from "../../../../../contexts/ProductPageStore";

const MightFit = ({ selectedVehicle, promptUserFor }) => {
  const showAdditionalFacetDrawer = useProductPageStore((x) => x.showAdditionalFacetDrawer);

  let vehicleDisplayText;
  let DescriptionComponent;
  let ctaText;
  let ctaTextLinkOnClick;

  if (selectedVehicle.vehicleBaseId)
    vehicleDisplayText = `${selectedVehicle.year} ${selectedVehicle.model}`;
  else if (selectedVehicle.raceTypeId)
    vehicleDisplayText = selectedVehicle.raceName;
  else if (selectedVehicle.engineDisplayName)
    vehicleDisplayText = selectedVehicle.engineDisplayName;

  if (promptUserFor === "RaceType") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {"Verify the Race Type for your "}
        <Typography font={"bold"} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
    ctaText = "Add Race Type";
    ctaTextLinkOnClick = () => {};
  } else if (promptUserFor === "Vehicle") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {"Verify the Vehicle for your "}
        <Typography font={"bold"} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
    ctTexta = "Add Vehicle";
    ctaTextLinkOnClick = () => {};
  } else if (promptUserFor === "Engine") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {"Verify the Engine for your "}
        <Typography font={"bold"} inline>
          {vehicleDisplayText}
        </Typography>
      </Typography>
    );
    ctaText = "Add Engine";
    ctaTextLinkOnClick = () => {};
  } else if (promptUserFor === "AdditionalFacets") {
    DescriptionComponent = (
      <Typography size={0.875}>
        {
          "This part may fit your vehicle and engine, complete options to verify fitment."
        }
      </Typography>
    );
    ctaText = "Complete Vehicle";
    ctaTextLinkOnClick = () => showAdditionalFacetDrawer()
  }

  return (
    <ErrorBoundary>
      <Area
        intent={"warning"}
        icon={"warning"}
        className={styles.vehicle_fitment}
      >
        <Typography font={"bold"}>This Part Might Fit</Typography>
        {DescriptionComponent}
        <TextLink
          className={styles.vehicle_fitment_link}
          size={0.875}
          chevron={"right"}
          onClick={ctaTextLinkOnClick}
        >
          {ctaText}
        </TextLink>
      </Area>
    </ErrorBoundary>
  );
};

export default MightFit;
