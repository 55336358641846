import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer';
import Script from 'next/script'
import { useProductPageStore } from '../../contexts/ProductPageStore';
import Accordion from 'Clutch/Molecules/Accordion'
import Typography from 'Clutch/Atoms/Typography'
import Svg from 'Clutch/Atoms/Svg'
import Card from 'Clutch/Atoms/Card'
import Skeleton from 'Clutch/Atoms/Skeleton'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary';
import styles from './ratings.module.scss'
import Button from 'Clutch/Molecules/Button';
import {
  createClickedSegmentEvent
} from "@speedwaymotors/clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { useThemeStore } from 'Stores/ThemeStore'

const RatingSummaryWithReviewSummary = () => {

  const summarizedReview = useProductPageStore(x => x.summarizedReview);
  const isRatingsAndReviewsAccordionOpen = useProductPageStore(x => x.isRatingsAndReviewsAccordionOpen);
  const toggleAccordion = useProductPageStore(x => x.toggleAccordion);


  return <RatingSummary>
    <>
      <div className={styles.summarizedReview}>
        <Typography font={'bold'} as={'h2'} className={styles.leftAlignedText}>
          Customers say
        </Typography>
        <Typography className={styles.leftAlignedText}>
          {summarizedReview}
        </Typography>
        {!isRatingsAndReviewsAccordionOpen && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.readallreviews_btn}
              intent={'subdued'}
              segmentEvent={createClickedSegmentEvent('ReadAllReviews')}
              type={'div'}
              size={'small'}
              text={'Read All Reviews'}
              onClick={() => toggleAccordion('RatingsAndReviews', true)}
            />
          </div>
        )}
      </div>
    </>
  </RatingSummary>
}

const RatingSummary = ({ children }) => {

  const rating = useProductPageStore(x => x.rating);
  const reviewCount = useProductPageStore(x => x.reviewCount);


  return (
    <ErrorBoundary>
      <Typography as={'h2'} size={1.25} font={'heading'}>
        {'Ratings & Reviews'}
      </Typography>
      {reviewCount ?
        <ErrorBoundary>
          <Typography size={0.875} tone={'subtle'} inline>
            {`${rating} `}
          </Typography>
          <Svg icon={'star'} brand={'secondary'} size={0.875} />
          <Typography size={0.875} tone={'subtle'} inline className={styles.reviews}>
            {` ${reviewCount} ${reviewCount === 1 ? 'Review' : 'Reviews'}`}
          </Typography>
        </ErrorBoundary> : false
      }
      {children}
    </ErrorBoundary>
  )
}

const NormalReviewGuts = ({ isPowerReviewsRendered, isPowerReviewsErrored }) => {

  const theme = useThemeStore(x => x.theme)
  const powerReviewsApiKey = useProductPageStore(x => x.powerReviewsApiKey);
  const powerReviewsMerchantGroupId = useProductPageStore(x => x.powerReviewsMerchantGroupId);
  const powerReviewsMerchantId = useProductPageStore(x => x.powerReviewsMerchantId);
  const title = useProductPageStore(x => x.title);
  const images = useProductPageStore(x => x.images);
  const manufacturerPartNumber = useProductPageStore(x => x.manufacturerPartNumber);
  const skuBaseNumber = useProductPageStore(x => x.skuBaseNumber);
  const brand = useProductPageStore(x => x.brand);
  const reviewCount = useProductPageStore(x => x.reviewCount);

  const [isPowerReviewsRenderedExperimental, setIsPowerReviewsRenderedExperimental] = useState(false)

  let show_QnA_ask_flag = true;

  const retry = (pwrFunc, count = 0, timeout = 250) => {
    try {
      pwrFunc();
    } catch (err) {
      if (count <= 25) {
        setTimeout(() => retry(pwrFunc, count + 1), timeout)
      } else {
        console.error(`failed to load power reviews`, err)
      }

    }
  }

  useEffect(() => {

    if (isPowerReviewsRendered) {

      const powerReviewsConfig = {
        'api_key': powerReviewsApiKey,
        'locale': 'en_US',
        'merchant_group_id': powerReviewsMerchantGroupId,
        'merchant_id': powerReviewsMerchantId,
        'name': title,
        'page_id': skuBaseNumber,
        'review_wrapper_url': `/ReviewSubmission?pr_page_id=${skuBaseNumber}`,
        'image_url': images && images.length ? images[0] : '//content.speedwaymotors.com/OtherImages/missingimage2.jpg',
        'description': title,
        'category_name': 'Cooling and Heating',
        'manufacturer_id': manufacturerPartNumber,
        'upc': null,
        'brand_name': brand ? brand.brandName : null,
        'url': window.location.href,
        'style_sheet': show_QnA_ask_flag ? '/mfe/pdp/powerreviews-with-question.css?v=1.0' : '/mfe/pdp/powerreviews.css?v=1.0',
        'on_render': (config, data) => {
          setIsPowerReviewsRenderedExperimental(true);
        },
        'components': {
          ReviewDisplay: 'pr-review-content',
          QuestionDisplay: 'pr-question-content'
        }
      }

      retry(() => pwr('render', powerReviewsConfig))

    }

  }, [isPowerReviewsRendered])

  return (
    <>
      <Typography as={'div'} id={'pr-review-content'} className={`pr-review-content-${theme}`} />
      {!isPowerReviewsRenderedExperimental ? (
        reviewCount === 0 ? (
          <Skeleton fullWidth height={44} />
        ) : (
          <>
            <Skeleton fullWidth height={250} />
            <Skeleton fullWidth height={35} />
            <Skeleton fullWidth height={687} />
            <Skeleton fullWidth height={120} />
            {[...Array(reviewCount > 5 ? 5 : reviewCount)].map((_, i) => (
              <Skeleton fullWidth height={205} key={`review_skeleton_${i}`} />
            ))}
          </>
        )
      ) : null}
      {isPowerReviewsErrored && (
        <Typography size={0.875} intent={'negative'} inline>
          {'Error: Reviews were not loaded'}
        </Typography>
      )}
    </>
  );
}

const Ratings = ({ stickyHeaderRef }) => {

  const toggleAccordion = useProductPageStore(x => x.toggleAccordion);
  const isRatingsAndReviewsAccordionOpen = useProductPageStore(x => x.isRatingsAndReviewsAccordionOpen);
  const summarizedReview = useProductPageStore(x => x.summarizedReview);
  const [isPowerReviewsRendered, setIsPowerReviewsRendered] = useState(false)
  const [isPowerReviewsErrored, setIsPowerReviewsErrored] = useState(false)

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  });

  return (
    <Card fill ref={stickyHeaderRef} className={styles.rating}>

      {inView ?
        <Script
          id={'powerreviews-4.1'}
          src={'https://ui.powerreviews.com/stable/4.1/ui.js'}
          onLoad={() => setIsPowerReviewsRendered(true)}
          onError={(e) => {
            console.error('Power Reviews script failed to load', e)
            setIsPowerReviewsErrored(true)
          }}
        /> : false}

      <div ref={ref}>
        <Accordion
          intent={'action'}
          iconClosed={'plus'}
          iconOpened={'minus'}
          size={0.875}
          summary={summarizedReview 
            ? <RatingSummaryWithReviewSummary />
            : <RatingSummary />}
          fill
          segmentAccordionName={'pdp ratings'}
          defaultOpen={isRatingsAndReviewsAccordionOpen}
          onChange={(isOpen) => toggleAccordion('RatingsAndReviews', isOpen)}
          alignIconToTop={true}>
          <div className={styles.accordionBody}>
            {inView ? <NormalReviewGuts
              isPowerReviewsRendered={isPowerReviewsRendered}
              isPowerReviewsErrored={isPowerReviewsErrored} /> : false}
          </div>
        </Accordion>
      </div>
    </Card>
  )
}

export default Ratings