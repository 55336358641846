import React from 'react'

import { useProductPageStore } from '../../contexts/ProductPageStore'
import FeatureFlagWrapper from 'Clutch/UtilityComponents/FeatureFlagWrapper'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Accordion from 'Clutch/Molecules/Accordion'
import Typography from 'Clutch/Atoms/Typography'
import Card from 'Clutch/Atoms/Card'

import styles from './qna.module.scss'

const QuestionsAndAnswers = ({ stickyHeaderRef }) => {

  const numberOfQuestions = useProductPageStore(x => x.numberOfQuestions);
  const isQuestionsAndAnswersAccordionOpen = useProductPageStore(x => x.isQuestionsAndAnswersAccordionOpen);
  const toggleAccordion = useProductPageStore(x => x.toggleAccordion);

  const qnaAccordionSummary = (
    <ErrorBoundary>
      <Typography as={'h2'} size={1.25} font={'heading'}>
        {'Questions & Answers'}
      </Typography>
      {numberOfQuestions && numberOfQuestions !== 0 ?
        <Typography size={0.875} tone={'subtle'} inline>
          {`${numberOfQuestions} ${numberOfQuestions === 1 ? 'Question' : 'Questions'}`}
        </Typography>
        : false
      }
    </ErrorBoundary>
  )

  const createPowerReviewOverrideMarkup = () => {
    return {
      __html:`<style>
      #pr-question-form-link {
        display: block !important;
      }
      </style>`
    }
  }
  console.info('isQuestionsAndAnswersAccordionOpen', isQuestionsAndAnswersAccordionOpen)
  return (
    <Card fill ref={stickyHeaderRef} className={styles.qna}>
      <FeatureFlagWrapper ff={"PDP_Show_QnA_Ask_Button"}>
        { <div 
          dangerouslySetInnerHTML={createPowerReviewOverrideMarkup()}
        /> }
      </FeatureFlagWrapper>
      <Accordion
        intent={'action'}
        iconClosed={'plus'}
        iconOpened={'minus'}
        size={0.875}
        summary={qnaAccordionSummary}
        fill
        segmentAccordionName={'pdp qna'}
        defaultOpen={isQuestionsAndAnswersAccordionOpen}
        onChange={(isOpen) => toggleAccordion('QuestionsAndAnswers', isOpen)}>
        
        <Typography as={'div'} id={'pr-question-content'} />
      </Accordion>
    </Card>
  )
}

export default QuestionsAndAnswers