import { useEffect, useState, useRef } from "react";
import { useProductPageStore } from "../../contexts/ProductPageStore";
import useFeatureFlag from "Clutch/Hooks/useFeatureFlag";
import Modal from "Clutch/Components/Modal/ModalWithPortal";
import { EngineFitmentFeedbackModalButton } from "./engineFitmentFeedback";

const EngineFitmentFeedbackWrapper = () => {
  try {
    const engineFitmentForSku = useProductPageStore(x => x.engineFitmentForSku);
    const internalRequestEngineFitment = useProductPageStore(x => x.internalRequestEngineFitment);
    const skuVariantNumber = useProductPageStore(x => x.skuVariantNumber);

    const feedbackEnabled = useFeatureFlag(
      "PDP_Show_Fitment_Feedback_Internals"
    );
    const [showFeedback, setShowFeedback] = useState(false);
    const modal = useRef(null);

    useEffect(() => {
      if (showFeedback) modal?.current?.open();
      if (!showFeedback) modal?.current?.close();
    }, [showFeedback]);

    let EngineFitmentFeedbackModalContents;
    if (!!engineFitmentForSku && !!feedbackEnabled) {
      EngineFitmentFeedbackModalContents =
        require("./engineFitmentFeedback").default;
    }

    return (
      <>
        <EngineFitmentFeedbackModalButton
          skuNumber={skuVariantNumber}
          showFeedback={showFeedback}
          setShowFeedback={setShowFeedback}
          shouldRender={((!!engineFitmentForSku || internalRequestEngineFitment) && !!feedbackEnabled)}
          internalRequestEngineFitment={internalRequestEngineFitment}
        />
        <Modal
          id={"engineFitmentFeedbackModal"}
          outsideClick
          ref={modal}
          customOnClose={() => setShowFeedback(false)}
        >
          {EngineFitmentFeedbackModalContents ? (
            <EngineFitmentFeedbackModalContents
              skuNumber={skuVariantNumber}
              engineFitmentData={engineFitmentForSku}
            />
          ) : (
            false
          )}
        </Modal>
      </>
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default EngineFitmentFeedbackWrapper;
