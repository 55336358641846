import React, { useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { useVehicleStore } from "Clutch/Stores/VehicleStore"
import { useProductPageStore } from '../../../contexts/ProductPageStore'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import Fits from './fitments/fits'
import DoesntFit from './fitments/doesntFit'
import SelectVehicle from './fitments/selectVehicle'

import { determineFitmentDisplayType, fitmentDisplayTypes } from './determineFitmentType'
import SelectFitment from './fitments/SelectFitment/SelectFitment'
import MightFit from './fitments/mightfit/mightFit'
import { createCheckFitmentRequest } from '../../../utils/fitment/checkFitmentUtils'

const VehicleFitment = ({}) => {

  const customerProjects = useVehicleStore(x => x.context.customerProjects)
  const fitmentType = useProductPageStore((x) => x.fitmentType);
  const checkFitment = useProductPageStore(x => x.checkFitment);
  const showFitmentDrawer = useProductPageStore(x => x.showFitmentDrawer);
  const fitment = useProductPageStore(x => x.fitment);
  const breadcrumb = useProductPageStore(x => x.breadcrumb);
  const excludeFromThisPartFitsMyVehicle = useProductPageStore(x => x.excludeFromThisPartFitsMyVehicle);

  const selectedCustomerProjectState = useVehicleStore(x => x.context.selectedCustomerProject)

  if (excludeFromThisPartFitsMyVehicle){
    return false;
  }

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  });

  useEffect(() => {
    if (inView && customerProjects && customerProjects.length) {
      const checkFitmentRequest = createCheckFitmentRequest(customerProjects);
      checkFitment(checkFitmentRequest)
    }
  }, [inView, customerProjects])

  const vehicleBaseProjectIds = customerProjects?.filter(customerVehicle => customerVehicle.vehicleBaseId).map(customerVehicle => customerVehicle.projectId);
  const raceTypeProjectIds = customerProjects?.filter(customerVehicle => customerVehicle.raceTypeId).map(customerVehicle => customerVehicle.projectId);
  const engineProjectIds = customerProjects?.filter(customerVehicle => customerVehicle.engineDisplayName && !customerVehicle.raceTypeId && !customerVehicle.vehicleBaseId).map(customerVehicle => customerVehicle.projectId);

  let vehicleBaseFitment = []
  let raceTypeFitment = []
  let engineFitment = []

  if (fitment && fitment.length) {
    vehicleBaseFitment = fitment.filter(f => vehicleBaseProjectIds?.includes(f.key))
    raceTypeFitment = fitment.filter(f => raceTypeProjectIds?.includes(f.key))
    engineFitment = fitment.filter(f => engineProjectIds?.includes(f.key))

  }
  const fitmentDisplayType = useMemo(() => determineFitmentDisplayType(fitmentType, customerProjects, selectedCustomerProjectState, vehicleBaseFitment, raceTypeFitment, engineFitment), [fitmentType, customerProjects, selectedCustomerProjectState, vehicleBaseFitment, raceTypeFitment, engineFitment])

  const vehicleBasesThatFit = vehicleBaseFitment && vehicleBaseFitment.length ?
    vehicleBaseFitment.filter(vehicle => vehicle && vehicle.value.fits).map(vehicle => { return { 'key': vehicle.key, ...vehicle.value } }) : []
  const raceTypesThatFit = raceTypeFitment && raceTypeFitment.length ?
    raceTypeFitment.filter(vehicle => vehicle && vehicle.value.fits).map(vehicle => { return { 'key': vehicle.key, ...vehicle.value } }) : []
  const enginesThatFit = engineFitment && engineFitment.length ?
    engineFitment.filter(vehicle => vehicle && vehicle.value.fits).map(vehicle => { return { 'key': vehicle.key, ...vehicle.value } }) : []
  const selectedVehicleBases = customerProjects ? customerProjects.filter(vehicle => vehicle && vehicle.vehicleBaseId === selectedCustomerProjectState?.vehicleBaseId) : []
  const selectedRaceTypes = customerProjects ? customerProjects.filter(vehicle => vehicle && vehicle.raceTypeId === selectedCustomerProjectState?.raceTypeId) : []
  const selectedEngines = customerProjects ? customerProjects.filter(vehicle => vehicle && vehicle.engineDisplayName && !vehicle.vehicleBaseId && !vehicle.raceTypeId && vehicle.engineDisplayName === selectedCustomerProjectState?.engineDisplayName) : []

  const selectedVehicle = [].concat((selectedVehicleBases || [])).concat((selectedRaceTypes || [])).concat((selectedEngines || []))[0]

  const matchingVehicleBaseFitment = vehicleBaseFitment ?
    vehicleBaseFitment.filter(baseFitment => baseFitment.key === selectedVehicle?.vehicleBaseId) : []
  const shopUrl = matchingVehicleBaseFitment && matchingVehicleBaseFitment.length ?
    matchingVehicleBaseFitment[0].value.shopUrl :
    selectedVehicle ? selectedVehicle.shopUrl : ""
  const selectedVehicleFitment = fitment?.find(fitment => fitment.key === selectedVehicle?.projectId);

  let FitmentDisplayComponent = null;
  if (fitmentDisplayType === fitmentDisplayTypes.selectVehicle ||
    fitmentDisplayType === fitmentDisplayTypes.selectEngine ||
    fitmentDisplayType === fitmentDisplayTypes.selectVehicleAndEngine) {
    FitmentDisplayComponent = (
      <SelectFitment type={fitmentDisplayType} />
    );
  } else if (
    fitmentDisplayType === fitmentDisplayTypes.fits ||
    fitmentDisplayType === fitmentDisplayTypes.direct ||
    fitmentDisplayType === fitmentDisplayTypes.universal
  ) {
    FitmentDisplayComponent = (
      <Fits
        selectedVehicle={selectedVehicle}
        vehicleBasesThatFit={vehicleBasesThatFit}
        raceTypesThatFit={raceTypesThatFit}
        enginesThatFit={enginesThatFit}
        customerProjects={customerProjects}
        showFitmentDrawer={showFitmentDrawer}
        fitmentType={fitmentDisplayType}
      />
    );
  } else if (fitmentDisplayType === fitmentDisplayTypes.needsMoreInfo) {
    FitmentDisplayComponent = (
      <MightFit
        selectedVehicle={selectedVehicle}
        promptUserFor={selectedVehicleFitment?.value?.promptUserFor}
      />
    );
  } else if (fitmentDisplayType === fitmentDisplayTypes.doesntFit) {
    FitmentDisplayComponent = (
      <DoesntFit
        selectedVehicle={selectedVehicle}
        vehicleBasesThatFit={vehicleBasesThatFit}
        raceTypesThatFit={raceTypesThatFit}
        enginesThatFit={enginesThatFit}
        breadcrumb={breadcrumb}
        partialFit={selectedVehicleFitment?.value?.partialFit}
        showFitmentDrawer={showFitmentDrawer}
        customerProjects={customerProjects}
        shopUrl={shopUrl}

      />
    );
  }

  return (
    <ErrorBoundary>
      {customerProjects && customerProjects.length ? <div ref={ref}></div> : false}
      {FitmentDisplayComponent}
    </ErrorBoundary>
  )
}

export default VehicleFitment