import React, { useContext, useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer';

import { useProductPageStore } from '../../contexts/ProductPageStore';

import Accordion from 'Clutch/Molecules/Accordion'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Divider from 'Clutch/Atoms/Divider'
import Svg from 'Clutch/Atoms/Svg'
import Html from 'Clutch/Atoms/Html'
import Card from 'Clutch/Atoms/Card'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import ColorFinder from 'Clutch/Utilities/ColorFinder'

import { validateDigitalAsset } from '../digitalAssets/digitalAssetUtils'
import DigitalAssets from '../digitalAssets/digitalAssets'
import styles from './details.module.scss'

// something on the context to make it okay to not being the loading state
// a component that will render a skeleton if flag to not be ready

const Details = ({ stickyHeaderRefs }) => {
  const tabs = useProductPageStore(x => x.tabs)?.filter(tab =>
    tab && tab.key && tab.key.length && tab.value && tab.value.length && tab.key !== "Installation Tips"
  );
  const digitalAssets = useProductPageStore(x => x.digitalAssets)?.filter(digitalAsset => validateDigitalAsset(digitalAsset));
  const isDetailsAccordionOpen = useProductPageStore(x => x.isDetailsAccordionOpen);
  const isDocsReadyForScroll = useProductPageStore(x => x.isDocsReadyForScroll);
  const manufacturerPartNumber = useProductPageStore(x => x.manufacturerPartNumber);
  const brand = useProductPageStore(x => x.brand);
  const rebates = useProductPageStore(x => x.rebates);
  const isProp65 = useProductPageStore(x => x.isProp65);
  const isCARB = useProductPageStore(x => x.isCARB);
  const cARBSummary = useProductPageStore(x => x.cARBSummary);
  const cARBDescription = useProductPageStore(x => x.cARBDescription);
  const isCarbAccordionOpen = useProductPageStore(x => x.isCarbAccordionOpen);
  const toggleAccordion = useProductPageStore(x => x.toggleAccordion);
  const setIsReadyForScrollData = useProductPageStore(x => x.setIsReadyForScrollData);

  const linkStyling = ColorFinder('action', null, null)
  const headerStyling = ColorFinder(null, 'subtle', null)

  useEffect(() => {
    if (isDetailsAccordionOpen !== isDocsReadyForScroll) {
      setIsReadyForScrollData(isDetailsAccordionOpen, 'Details')
    }
  }, [isDetailsAccordionOpen]);

  const [dummyRef, _] = useInView()

  if ((tabs || []).length === 0 &&
    !isProp65 &&
    !isCARB &&
    (digitalAssets || []).length === 0) {
    return false
  }


  if (!stickyHeaderRefs) {
    stickyHeaderRefs = {}
  }



  const detailsAccordionSummary = (
    <Typography as={'h2'} size={1.25} font={'heading'}>
      {manufacturerPartNumber && brand && brand.brandName ?
        `${brand.brandName} ${manufacturerPartNumber} Details` : 'Details'}
    </Typography>
  )

  const carbAccordionSummary = (
    <div>
      <Typography as={'h2'} size={1.25} font={'heading'}>
        CARB Warning
      </Typography>
      <Typography size={0.875} tone={'subtle'} inline>
        {cARBSummary}
      </Typography>
    </div>
  )



  return (
    <Card fill dataTestId={'pdp_details'} ref={isDetailsAccordionOpen ? dummyRef : stickyHeaderRefs.detailsClosed}>
      <Accordion
        intent={'action'}
        iconClosed={'plus'}
        iconOpened={'minus'}
        size={0.875}
        summary={detailsAccordionSummary}
        fill
        segmentAccordionName={'pdp details'}
        defaultOpen={isDetailsAccordionOpen}
        onChange={(isOpen) => toggleAccordion('Details', isOpen)}>
        <div ref={stickyHeaderRefs.detailsOpen}>
          {tabs?.map((tab, i) =>
            <ErrorBoundary key={`details_${tab.key}_${i}`}>
              {i != 0 ?
                <Typography font={'bold'} size={1} data-testid={`details_${tab.key}`}>
                  {tab.key}
                </Typography> : false}
              <div>
                <Html html={tab.value} />
              </div>
            </ErrorBoundary>
          )}
          {(rebates || []).length ?
            <>
              <Typography font={'bold'} size={1} data-testid={`details_rebates`} id={'pdp_details_rebates'} className={styles.pdp_details_rebates}>
                Rebates
              </Typography>
              {
                rebates.map((rebate, i) =>
                  <ErrorBoundary key={`details_rebates_${i}`}>
                    <Html html={rebate.rebateDescription} />
                  </ErrorBoundary>
                )
              }
            </>
            :
            false
          }
        </div>
        {digitalAssets?.length ?
          <div ref={stickyHeaderRefs.guidesDocs}>
            <Divider className={styles.guides_documents_divider} data-testid={`guides_docs_section`} />
            <Typography font={'bold'} id={'pdp_details_guides_docs'} className={styles.pdp_details_guides_docs}>
              {'Guides & Documents'}
            </Typography>
            <DigitalAssets digitalAssets={digitalAssets} />
          </div>
          :
          false
        }
        {isProp65 || isCARB ?
          <div ref={stickyHeaderRefs.carbProp65}>
            <Divider className={styles.california_laws_divider} data-testid={'california_laws_divider'} />
            {cARBDescription ?
              <div>
                <Accordion
                  intent={'action'}
                  iconClosed={'plus'}
                  iconOpened={'minus'}
                  size={0.875}
                  summary={carbAccordionSummary}
                  segmentAccordionName={'pdp carb'}
                  defaultOpen={isCarbAccordionOpen}
                  onChange={(isOpen) => toggleAccordion('Carb', isOpen)}>
                  <Html html={cARBDescription} />
                </Accordion>
                <Divider className={styles.california_laws_divider} data-testid={'california_laws_divider'} />
              </div>
              :
              false
            }

            {isProp65 ?
              <div className={styles.california_laws_container}>
                <Svg className={styles.california_laws_icon} icon={'prop65'} size={1} />
                <TextLink
                  size={0.875}
                  inline
                  underline
                  targetBlank
                  href={'https://www.speedwaymotors.com/Info/prop65'}
                  data-testid={'details_prop65'}>
                  CA Prop 65
                </TextLink>
              </div>
              :
              false
            }
            {isCARB ?
              <div className={styles.california_laws_container}>
                <Svg className={styles.california_laws_icon} icon={'CARB'} size={1} />
                <TextLink
                  size={0.875}
                  inline
                  underline
                  targetBlank
                  href={'https://www.speedwaymotors.com/Info/carb'}
                  data-testid={'details_CARB'}>
                  CARB warning
                </TextLink>
              </div>
              :
              false
            }
          </div>
          :
          false
        }
      </Accordion>
    </Card>
  )
}

function withLazyClientSkeleton(Component){
  return (props) => {
    const isHydrated = useProductPageStore(x => x.isLazyDataHydrated);

    if (!isHydrated)
      {return <>
        <div>not so fancy skeleton</div>
      </>}

   return (<>
      <Component {...props}/>
    </>)
  }
}

export default Details


