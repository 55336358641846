import React, { useEffect, useState } from 'react'
import { useProductPageStore } from '../../contexts/ProductPageStore'

import { useInView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'

const MakerEnhance = dynamic(() => import('maker-enhance'), {ssr: false})

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import styles from './aplusCardContent.module.scss'

const AplusCardContent = ({}) => {
  const aPlusContentScrollToLock = useProductPageStore(x => x.aPlusContentScrollToLock)

  const [aPlusContentLoaded, setAPlusContentLoaded] = useState(false)

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  });

  useEffect(() => {
    if(inView && !aPlusContentScrollToLock?.active){
      setAPlusContentLoaded(true)
    }
  }, [inView, aPlusContentScrollToLock?.active])

  let aPlusShouldLoad = (inView && !aPlusContentScrollToLock?.active)
  console.info('aPlusShouldLoad', aPlusShouldLoad)
  return (
    <ErrorBoundary>
      <div ref={ref}></div>
      <div className={styles.aplus_card_wrapper}>
        {aPlusShouldLoad || aPlusContentLoaded ? <MakerEnhance user={'speedway'} index={'card'} /> : false}
      </div>
    </ErrorBoundary>
  )
}

export default AplusCardContent