import { useState } from "react";
import Typography from "Clutch/Atoms/Typography/Typography";
import Button from "Clutch/Molecules/Button/Button";
import EngineFitmentRow from "./engineFitmentFeedbackRow"
import { sendFitmentFeedbackToZendesk } from "./sendFitmentFeedbackToZendesk";

import styles from "./engineFitmentFeedback.module.scss";

export const EngineFitmentFeedbackModalButton = ({
  skuNumber,
  showFeedback,
  setShowFeedback,
  shouldRender,
  internalRequestEngineFitment
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  if (!shouldRender) return false;

  if (internalRequestEngineFitment){
    return (
      <Button
        className={styles.feedbackButton}
        brand={"primary"}
        size={"small"}
        isWaiting={isLoading}
        onClick={() => handleRequestEngineFitment(skuNumber, setIsLoading, setSuccess)}
        segmentEvent={{ event: "engineFitmentFeedbackButton" }}
      >{success ? 
        <Typography size={1}>Engine Fitment Requested</Typography>
        : <Typography size={1}>Request Engine Fitment</Typography>
      }
      </Button>
    );
  }

  return (
    <Button
      className={styles.feedbackButton}
      brand={"primary"}
      size={"small"}
      onClick={() => setShowFeedback(!showFeedback)}
      segmentEvent={{ event: "engineFitmentFeedbackButton" }}
    >
      <Typography size={1}>Engine Fitment Feedback</Typography>
    </Button>
  );
};

const EngineFitmentFeedbackModalContents = ({
  skuNumber,
  engineFitmentData,
}) => {
  if (!engineFitmentData) return false;

  return (
    <div className={styles.feedbackWrapper}>
      <Typography
        font={"bold"}
        as={"h2"}
        brand={"primary"}
        style={{ marginBottom: "1rem" }}
      >
        Engine Fitment Feedback
      </Typography>
      {(engineFitmentData || []).map((engine) => {
        return (
          <EngineFitmentRow
            skuNumber={skuNumber}
            engine={engine}
            key={`fitment-row-${engine.engineId}`}
          />
        );
      })}
    </div>
  );
};

async function handleRequestEngineFitment(skuNumber, setIsLoading, setSuccess) {
  setIsLoading(true);
  var input = {
    skuVariant: skuNumber
  };

  var success = await sendFitmentFeedbackToZendesk(input);
  setSuccess(success);
  setIsLoading(false);
}

export default EngineFitmentFeedbackModalContents;
