import styles from "./ImageBlurrable.module.scss";
import Head from "next/head";
import Image from "next/legacy/image";
import classNames from "classnames";
import { useEffect, useState } from "react";

const buildUrl = (src, size) => `/_next/image?url=${encodeURIComponent(src)}&w=${size}&q=1`

const lowResImageLoader = ({ src, width, quality }) => {
  return buildUrl(src, 550);
}


const ImageBlurrable = ({
  priority,
  ...props
}) => {

  // load a low res next image and a hidden responsive high res next image (both preloaded)
  // once the high res image is loaded, change the props on the low res image to be the high res


  // currently loading a grainy image doesn't give you credit for LCP, so fetchpriority is the best we can do
  // there's talk about the LCP rules changing, which is why I kept this component around
  // https://github.com/w3c/largest-contentful-paint/issues/71
  // so this feature flag exists for when perhaps that day comes...
  const enableImageBlurPreload = false
  const [isHighResImageLoaded, setIsHighResImageLoaded] = useState(!enableImageBlurPreload);

  const lowResImageProps = {
    priority,
    fetchPriority: priority ? "high" : undefined, // highest could block FCP until the image is there (we don't want that!)
    layout: 'responsive',
    loader: lowResImageLoader, 
    ...props,
  }

  const highResImageProps = {
    priority,
    fetchPriority: priority ? "high" : undefined, // highest could block FCP until the image is there (we don't want that!)
    layout: 'responsive',
    onLoadingComplete: () => {
      // this may trigger the high res image to load (via the showLowResImage condition)
      setIsHighResImageLoaded(true);
    },
    ...props
  }

  const showLowResImage = priority && !isHighResImageLoaded;
  const displayedImageProps = showLowResImage ? lowResImageProps : highResImageProps;

  return (
    <>
      <Image {...displayedImageProps}/>
      { enableImageBlurPreload && priority && <div style={{display:'none'}}><Image {...highResImageProps}/></div> }
    </>
  );
};

export default ImageBlurrable;
