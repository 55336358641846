import React from 'react'
import dynamic from 'next/dynamic'
import { useProductPageStore } from '../../contexts/ProductPageStore'
import Accordion from 'Clutch/Molecules/Accordion'
import Typography from 'Clutch/Atoms/Typography'
import Divider from 'Clutch/Atoms/Divider'
import Carousel from 'Clutch/Organisms/Carousel'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Card from 'Clutch/Atoms/Card'
import styles from './videosAndArticles.module.scss'
import RecommendationSection from 'Clutch/Organisms/RecommendationSection'
import { useRecommendationStore } from "@speedwaymotors/clutch/Stores/RecommendationStore/RecommendationStore";

const ReactPlayer = dynamic(() => import('react-player/youtube'), { ssr: false })
const VideosAndArticles = ({ stickyHeaderRef, testData }) => {

  const videos = useProductPageStore(x => x.videos);
  const toggleAccordion = useProductPageStore(x => x.toggleAccordion);
  const isVideosAndArticlesAccordionOpen = useProductPageStore(x => x.isVideosAndArticlesAccordionOpen);

  const section = 'PDP_Articles';

  const getRecommendationSection = useRecommendationStore((x) => x.getRecommendationSection);

  // this is just to see if we have any articles
  // then we use the reusable RecommendationSection component for the carousel/display/etc...
  const articles = ( testData && testData.sectionType === section ? testData.recommendationGroups?.[0]?.recommendations : getRecommendationSection(section)?.recommendationGroups?.[0]?.recommendations)

  const videosAccordionSummary = (
    <>
      <Typography as={'h2'} size={1.25} font={'heading'} className={styles.summary_description}>
        {'Videos & Articles'}
      </Typography>
      <Typography size={0.875} tone={'subtle'} inline>
        {`${(articles || []).length + (videos || []).length} ${(articles || []).length + (videos || []).length === 1 ? 'item' : 'items'}`}
      </Typography>
    </>
  )

  return (
    <ErrorBoundary>
      {((videos && videos.length)
        || (articles && articles.length)) ?
        <Card fill dataTestId={'pdp_videos_and_articles'} ref={stickyHeaderRef} className={styles.vna}>
          <Accordion
            intent={'action'}
            iconClosed={'plus'}
            iconOpened={'minus'}
            size={0.875}
            summary={videosAccordionSummary}
            fill
            segmentAccordionName={'pdp videos and articles'}
            defaultOpen={isVideosAndArticlesAccordionOpen}
            onChange={(isOpen) => toggleAccordion('VideosAndArticles', isOpen)}>
            <Divider className={styles.divider} />
            <ErrorBoundary>
              {videos && videos.length ?
                <>
                  <Typography size={1} font={'bold'} className={styles.section_title}>
                    Videos for this product
                  </Typography>
                  <Carousel data-testid={'pdp_videos'} id={'pdp_videos'}>
                    {videos.map(video_url =>
                      <Carousel.Item className={styles.video} data-testid={`pdp_video_${video_url}`} key={`video_${video_url}`}>
                        <div className={styles.card_container}>
                          <Card noPadding>
                            <div className={styles.video_thumbnail}>
                              <ReactPlayer
                                light
                                playing
                                url={`https://www.youtube.com/watch?v=${video_url}`}
                                width={'100%'}
                                height={'100%'} />
                            </div>
                          </Card>
                        </div>
                      </Carousel.Item>
                    )}
                  </Carousel>
                </>
                :
                false
              }
            </ErrorBoundary>
            <ErrorBoundary>
              {articles && articles.length ?
                <RecommendationSection section={section} displayType={"Carousel"} data-testid={'pdp_articles'} extendGuttersForCarousel showCarouselGradient={true}/>
                :
                false
              }
            </ErrorBoundary>
          </Accordion>
        </Card>
        :
        false
      }
    </ErrorBoundary>
  )
}

export default VideosAndArticles