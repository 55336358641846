import React, { useContext } from "react";

import Area from "Clutch/Molecules/Area";
import Typography from "Clutch/Atoms/Typography";
import TextLink from "Clutch/Atoms/TextLink";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";

import { useVehicleStore } from "Clutch/Stores/VehicleStore";

import styles from "./SelectFitment.module.scss";
import { fitmentDisplayTypes } from "../../determineFitmentType";
import classNames from "classnames";

const SelectFitment = ({ type }) => {
  const openAddOrSelectCustomerProjectExperience = useVehicleStore(
    (state) => state.openAddOrSelectCustomerProjectExperience
  );

  let fitmentHeader = "Select a vehicle to verify Fitment";
  let fitmentLink = (
    <TextLink
      className={styles.vehicle_fitment_link}
      size={0.875}
      chevron={"right"}
      onClick={() => openAddOrSelectCustomerProjectExperience()}
      data-testid={`pdp-select-vehicle-for-fitment`}
    >
      Select Vehicle
    </TextLink>
  );
  if (type === fitmentDisplayTypes.selectEngine) {
    fitmentHeader = "Select an engine to verify Fitment";
    fitmentLink = (
      <TextLink
        className={styles.vehicle_fitment_link}
        size={0.875}
        chevron={"right"}
        onClick={() => openAddOrSelectCustomerProjectExperience()}
        data-testid={`pdp-select-engine-for-fitment`}
      >
        Select Engine
      </TextLink>
    );
  } else if (type === fitmentDisplayTypes.selectVehicleAndEngine) {
    fitmentHeader = "Select a vehicle and engine to verify Fitment";
    fitmentLink = (
      <>
        <div className={styles.vehicle_and_engine_link_container}>
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            chevron={"right"}
            onClick={() => openAddOrSelectCustomerProjectExperience()}
            data-testid={`pdp-select-vehicle-for-fitment`}
          >
            Select Vehicle
          </TextLink>
          <TextLink
            className={styles.vehicle_fitment_link}
            size={0.875}
            chevron={"right"}
            onClick={() => openAddOrSelectCustomerProjectExperience()}
            data-testid={`pdp-select-engine-for-fitment`}
          >
            Select Engine
          </TextLink>
        </div>
        <TextLink
          className={styles.vehicle_fitment_link}
          size={0.875}
          chevron={"right"}
          onClick={() => openAddOrSelectCustomerProjectExperience()}
          data-testid={`pdp-select-dont-know-engine-for-fitment`}
        >
          Don't know what engine you have?
        </TextLink>
      </>
    );
  }

  return (
    <ErrorBoundary>
      <Area
        brand={"secondary"}
        className={styles.vehicle_fitment}
        icon={"universal_fit"}
        centerIcon={false}
      >
        <Typography className={styles.vehicle_fitment_header} font={"bold"}>
          {fitmentHeader}
        </Typography>
        {fitmentLink}
      </Area>
    </ErrorBoundary>
  );
};

export default SelectFitment;
