import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'
import useWindow from 'Clutch/Hooks/useWindow'
import classNames from 'classnames'

import dynamic from 'next/dynamic'
import Image from 'next/image'

const ReactPlayer = dynamic(() => import('react-player/youtube'), { ssr: false })

import { useProductPageStore } from '../../contexts/ProductPageStore'
import PanelContext from "Clutch/Contexts/PanelContext";
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import Panel from 'Clutch/Atoms/Panel'
import Carousel from 'Clutch/Organisms/Carousel'
import Snapper from 'Clutch/Molecules/Snapper'
import PaginationControl from 'Clutch/Atoms/PaginationControl'
import Svg from 'Clutch/Atoms/Svg'
import AddToList from "../addToList/addToList"
import ColorFinder from 'Clutch/Utilities/ColorFinder'
import Ribbon from 'Clutch/Atoms/Ribbon';
import { combineImagesAndVideos } from './galleryUtils'
import styles from './gallery.module.scss'
import styled from 'styled-components'
import Skeleton from 'Clutch/Atoms/Skeleton'
import GalleryImage from './galleryImage'
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag'
import GalleryTabs from './galleryTabs'

const Gallery = ({ }) => {
  const renderCounter = useRef(0);
  renderCounter.current = renderCounter.current + 1
  console.debug(`Gallery Component Renders: ${renderCounter.current}`)

  const images = useProductPageStore((state) => state.images);
  const videos = useProductPageStore((state) => state.videos);
  const spinImages = useProductPageStore((state) => state.spinImages);
  const hasActiveRebate = useProductPageStore((state) => state.hasActiveRebate);
  const title = useProductPageStore((state) => state.title);
  const skuBaseNumber = useProductPageStore((state) => state.skuBaseNumber);

  const combinedProductImagesAndVideos = useMemo(() =>
    combineImagesAndVideos(images, videos, spinImages),
    [images, videos, spinImages])
  const mobileSwipeOffset = .07
  const [activeTab, setActiveTab] = useState('product')
  const [media, setMedia] = useState({product: combinedProductImagesAndVideos})
  const [zoomed, setZoomed] = useState(false)
  const [hidePaginationControls, setHidePaginationControls] = useState(true)
  const [userEngagedWithGallery, setUserEngagedWithGallery] = useState(false)
  const [position, setPosition] = useState(0)
  const mobileSnapperRef = useRef(null)
  const desktopSnapperRef = useRef(null)
  const [isDesktop, setIsDesktop] = useState(false);
  const [userHasLoopedRight, setUserHasLoopedRight] = useState(false);
  let galleryInfiniteScrollFlag = useFeatureFlag("PDP_Image_Gallery_Infinite_Scroll");

  let selectedMedia = media[activeTab]

  useWindow(() => {
    setMedia(media => {
      return {
        ...media,
        product: [...combinedProductImagesAndVideos]
      }
    })
    import('./galleryUtils').then(f => {
      f.getThumbnailImageForVideos(selectedMedia).then((data) => {
        setMedia(media => {
          return {
            ...media,
            product: [...data]
          }
        })
      })
    })
    setIsDesktop(window.matchMedia('(min-width: 768px)').matches)
  })

  const moveRight = () => {
    let new_position
    if (position === (selectedMedia.length - 1)) {
      setUserHasLoopedRight(true)
      new_position = 0
    } else {
      new_position = position + 1
    }
    scrollToPosition(new_position)
  }

  const moveLeft = () => {
    let new_position
    if (position === 0) {
      new_position = selectedMedia.length - 1
    } else {
      new_position = position - 1
    }
    scrollToPosition(new_position)
  }

  const scrollToPosition = (new_position, delay = false) => {
    const mobileSnapper = mobileSnapperRef.current
    const mobileWidth = mobileSnapper.getBoundingClientRect().width
    if (delay) {
      setTimeout(() => {
        mobileSnapper.scrollLeft = mobileWidth * new_position
      }, 100);
    } else {
      mobileSnapper.scrollLeft = mobileWidth * new_position
    }

    const desktopSnapper = desktopSnapperRef.current
    const desktopWidth = desktopSnapper.getBoundingClientRect().width
    if (delay) {
      setTimeout(() => {
        desktopSnapper.scrollLeft = desktopWidth * new_position
      }, 100);
    } else {
      desktopSnapper.scrollLeft = desktopWidth * new_position
    }
    setPosition(new_position)
  }

  useEffect(() => {
    if (position != 0 || userEngagedWithGallery) {
      setUserEngagedWithGallery(true)
    }
  }, [position])

  useEffect(() => {
    setZoomed(false)
  }, [position])

  useEffect(() => {
    setPosition(0)
    scrollToPosition(0)
  }, [activeTab])

  const onScrollMobile = () => {
    const mobileSnapper = mobileSnapperRef.current
    if (!mobileSnapper.children)
      return

    const mobileFirstChild = mobileSnapper.children[0]
    const mobileFirstChildOffset = mobileSnapper.getBoundingClientRect().left - mobileFirstChild.getBoundingClientRect().left
    const mobileFirstChildWidth = mobileFirstChild.getBoundingClientRect().width
    let newPosition = parseInt(Math.round(Math.abs(mobileFirstChildOffset) / mobileFirstChildWidth))

    if (Math.abs(mobileFirstChildOffset) / mobileFirstChildWidth >= selectedMedia.length - 1 + mobileSwipeOffset && galleryInfiniteScrollFlag) {
      mobileSnapper.scrollTo({ left: 0, behavior: 'smooth' })
      setUserHasLoopedRight(true)
    } else if (mobileFirstChildOffset / mobileFirstChildWidth < -mobileSwipeOffset && userHasLoopedRight && galleryInfiniteScrollFlag) {
      mobileSnapper.scrollTo({ left: mobileFirstChildWidth * selectedMedia.length, behavior: 'smooth' })
    } else {
      setPosition(newPosition)
    }
  }

  const onScrollDesktop = () => {
    const desktopSnapper = desktopSnapperRef.current
    if (!desktopSnapper.children)
      return

    const desktopFirstChild = desktopSnapper.children[0]
    const desktopFirstChildOffset = desktopSnapper.getBoundingClientRect().left - desktopFirstChild.getBoundingClientRect().left
    const desktopFirstChildWidth = desktopFirstChild.getBoundingClientRect().width
    let newPosition = parseInt(Math.round(Math.abs(desktopFirstChildOffset) / desktopFirstChildWidth))

    if (Math.abs(desktopFirstChildOffset) / desktopFirstChildWidth >= selectedMedia.length - 1 && galleryInfiniteScrollFlag) {
      desktopSnapper.scrollTo({ left: 0, behavior: 'smooth' })
      setPosition(0)
      setUserHasLoopedRight(true)
    } else if (desktopFirstChildOffset / desktopFirstChildWidth < 0 && userHasLoopedRight && galleryInfiniteScrollFlag) {
      desktopSnapper.scrollTo({ left: desktopFirstChildWidth * selectedMedia.length, behavior: 'smooth' })
      setPosition(media.length - 1)
    } else {
      setPosition(newPosition)
    }
  }

  const { color } = ColorFinder(undefined, undefined, 'secondarypalette_purple')

  const zoomFunction = (desktopSnapperRef, e) => {
    var snapperRect = desktopSnapperRef.current.getBoundingClientRect()

    var snapperWidth = snapperRect.width
    var snapperHeight = snapperRect.height

    var cursorXOffset = (e.clientX - snapperRect.x) / snapperWidth
    var cursorYOffset = (e.clientY - snapperRect.y) / snapperHeight

    if (cursorXOffset > 1 || cursorXOffset < 0 || cursorYOffset > 1 || cursorYOffset < 0) {
      return
    }

    var zoomXOffset = snapperWidth * cursorXOffset
    var zoomYOffset = snapperHeight * cursorYOffset * 2

    e.target.style['left'] = `${-zoomXOffset}px`
    e.target.style['top'] = `calc(100% + ${-zoomYOffset}px)`

    e.target.style['width'] = `200%`
    e.target.style['max-width'] = `200%`
    e.target.style['height'] = `200%`
    e.target.style['max-height'] = `200%`
  }

  const dotRowLength = 16
  let dotRows = []
  for (let i = 0, rowHasOneMoreDot = 1; i < selectedMedia.length; i += dotRowLength + rowHasOneMoreDot, rowHasOneMoreDot = 1 - rowHasOneMoreDot) {
    dotRows.push(selectedMedia.slice(i, i + dotRowLength + rowHasOneMoreDot));
  }

  return (
    <ErrorBoundary>
      <ErrorBoundary>
        <div className={classNames(styles.mobile_snapper, selectedMedia.some(x => !x.isImage) && styles.mobile_snapper_with_video)}>
          {hasActiveRebate && <Ribbon text={'Rebate'} brand={'secondary'} />}
          <Snapper ref={mobileSnapperRef} onScroll={onScrollMobile}>
            {selectedMedia?.length ? selectedMedia.map((item, i) =>
              <div key={`mobile_${item.media}`} className={classNames(styles.foo, i === selectedMedia.length - 1 && styles.last_image, i === 0 && styles.first_image)} aria-hidden={i === position ? 'false' : 'true'}>
                <div className={classNames(styles.image_main_mobile, !item.isImage && styles.video_main_mobile)} >
                  {item.isImage ?
                    <GalleryImage
                      src={`https:${item.media}`}
                      alt={title}
                      index={i}
                      isDesktop={false}
                      customerImage={item.customerImage}
                      spinImages={item.spinImages}
                    /> :
                    <ReactPlayer
                      light
                      playing
                      className={styles.video_main_mobile}
                      url={`https://www.youtube.com/watch?v=${item.media}`}
                      previewTabIndex={i === position ? '0' : '-1'}
                      controls='true'
                      onError={() => { console.error('error playing youtube video.') }} />}
                </div>
              </div>
            ) : <div className={styles.gallery_skeleton}>
              <Skeleton fullWidth fullHeight />
            </div>}
          </Snapper>
        </div>
        <div className={styles.gallery_window}
          onMouseEnter={() => setHidePaginationControls(false)}
          onMouseLeave={() => setHidePaginationControls(true)}>
          <PaginationControl
            className={classNames(styles.pager,
              styles.pager_left,
              hidePaginationControls && styles.pager_seethrough,
              zoomed && styles.pager_hidden,
              (selectedMedia || []).length <= 1 && styles.pager_hidden,
              position === 0 && !(galleryInfiniteScrollFlag && userHasLoopedRight) && styles.pager_hidden)}
            isStaticBackground
            direction={'left'}
            brand={'secondarypalette_purple'}
            onClick={moveLeft}
            aria-label={'pager_left'} />
          <div className={styles.desktop_snapper}>
            {hasActiveRebate &&
              <div className={styles.rebate_ribbon_container}>
                <Ribbon text={'Rebate'} brand={'secondary'} />
              </div>}
            <Snapper ref={desktopSnapperRef} onScroll={onScrollDesktop}>
              {selectedMedia?.length ? selectedMedia.map((item, i) =>
                <div
                  className={classNames(styles.image_main_desktop_wrapper, i === selectedMedia.length - 1 && styles.last_image, i === 0 && styles.first_image)}
                  key={`desktop_${item.media}`}
                  aria-hidden={i === position ? 'false' : 'true'}>
                  <div className={classNames(styles.image_main_desktop, !item.isImage && styles.video_main_desktop)}>
                    {item.isImage ?
                      <GalleryImage
                        src={`https:${item.media}`}
                        alt={title}
                        index={i}
                        position={position}
                        zoomed={zoomed && !(item.spinImages || []).length}
                        isDesktop={true}
                        customerImage={item.customerImage}
                        spinImages={item.spinImages}
                        onClick={!(item.spinImages || []).length ? (e) => {
                          if (zoomed) {
                            e.target.style['left'] = `0px`
                            e.target.style['top'] = `0px`
                            e.target.style['width'] = `0`
                            e.target.style['max-width'] = `100%`
                            e.target.style['height'] = `0`
                            e.target.style['max-height'] = `100%`
                            setHidePaginationControls(false)
                          } else {
                            zoomFunction(desktopSnapperRef, e)
                            setHidePaginationControls(true)
                          }
                          setZoomed(!!!zoomed)
                        } : () => { }}
                        onMouseMove={!(item.spinImages || []).length ? (e) => {
                          if (zoomed) {
                            zoomFunction(desktopSnapperRef, e)
                          }
                        } : () => { }}
                      /> :
                      <ReactPlayer
                        light
                        playing
                        className={styles.video_main}
                        url={`https://www.youtube.com/watch?v=${item.media}`}
                        previewTabIndex={i === position ? '0' : '-1'}
                        controls='true'
                        onError={() => { console.error('error playing youtube video.') }} />}

                  </div>
                </div>
              ) : <div className={styles.gallery_skeleton}>
                <Skeleton fullWidth fullHeight />
              </div>}
              <AddToList className={styles.pdp_add_to_list} dataTestId={'pdp_add_to_list_addtl_desktop_placement'} />
            </Snapper>
          </div>
          <PaginationControl
            className={classNames(styles.pager,
              styles.pager_right,
              hidePaginationControls && styles.pager_seethrough,
              zoomed && styles.pager_hidden,
              (selectedMedia || []).length <= 1 && styles.pager_hidden,
              position === media.length - 1 && !galleryInfiniteScrollFlag && styles.pager_hidden)}
            isStaticBackground
            direction={'right'}
            brand={'secondarypalette_purple'}
            onClick={moveRight}
            aria-label={'pager_right'} />

        </div>
      </ErrorBoundary>
      <div>
        <GalleryTabs skuBase={skuBaseNumber} setMedia={setMedia} combinedProductImagesAndVideos={combinedProductImagesAndVideos} onChange={(tab) => { setActiveTab(tab)}}/>
        {dotRows.map((row, j) =>
          <div className={classNames(styles.mobile_image_dot_row, j !== 0 && row.length % 2 === (j % 2) && styles.row_offset)} key={`row_${j}`}>
            {row.map((item, i) =>
              item.isImage ?
                <StyledDot
                  key={`dot_item_${i}`}
                  isSelected={item.media === selectedMedia[position]?.media}
                  backgroundColor={color}
                  className={classNames(styles.dot, styles.image_dot, i == row.length - 1 && styles.last_dot)} />
                :
                <Svg key={`svg_item_${i}`} className={classNames(styles.dot, i == row.length - 1 && styles.last_dot)} color={item.media === selectedMedia[position]?.media ? color : "#DDD"} intent={'subdued'} icon={'play'} size={0.5} />
            )}
          </div>
        )}

        {isDesktop &&
          <Panel className={classNames(styles.image_bar, styles.mobile_image_thumbnail)}>
            <Carousel id={'pdp_gallery_filmstrip'} layer={1}>
              {selectedMedia.map((item, i) => {
                return (
                  <ErrorBoundary key={`image_bar_${item.isImage ? item.media : item.thumbnailImage}_${i}`}>
                    <Carousel.Item className={styles.image_carousel_item_override}>
                      <StyledImageWrapper
                        className={classNames(styles.image_thumbnail_wrapper, !item.isImage ? styles.video_thumbnail_play : null)}
                        isSelected={item.media === selectedMedia[position]?.media}
                        borderColor={color}
                        onClick={() => { scrollToPosition(i) }}>
                        <Panel layer={0} className={classNames(styles.image_thumbnail, !item.isImage ? styles.video_thumbnail : null)}>
                          <div className={item.isImage ? styles.image_dimensions : styles.video_dimensions}>
                            <Image
                              src={item.isImage ? `https:${item.media}` : item.thumbnailImage}
                              layout={'fill'}
                              sizes={'72px'}
                              objectFit={item.customerImage ? 'contain' : 'cover'}
                              quality={65}
                              alt={title}
                              onClick={() => { scrollToPosition(i) }}
                              lazyBoundary={'0px'} />
                          </div>
                        </Panel>
                        <span className={!item.isImage ? styles.video_thumbnail_after : null} onClick={() => { scrollToPosition(i) }}> </span>
                        <span className={(item.spinImages || []).length ? styles.video_thumbnail_after : null} onClick={() => { scrollToPosition(i) }}> </span>
                        {(item.spinImages || []).length ?
                          <PanelContext.Provider value={{ inverseTheme: true, layer: 0 }}>
                            <Svg icon={'360-degree'} size={1.5} tone={'contrast'} invert className={styles.spin_icon} />
                          </PanelContext.Provider>
                          : false}
                      </StyledImageWrapper>
                      <span className={!item.isImage ? styles.video_thumbnail_play_after : null} onClick={() => { scrollToPosition(i) }}> </span>
                    </Carousel.Item>
                  </ErrorBoundary>
                )
              })}
            </Carousel>
          </Panel>}
      </div>
    </ErrorBoundary>
  )
}

const StyledDot = styled.div`
  ${props => props.isSelected ? `background-color: ${props.backgroundColor};` : `background-color: #DDD;`};
`

const StyledImageWrapper = styled.div`
  ${props => props.isSelected ? `border-color: ${props.borderColor};` : `border-color: transparent;`};
`

export default Gallery