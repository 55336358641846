import React, { useState, useEffect, useContext } from 'react'
import { useInView } from 'react-intersection-observer'

import Card from 'Clutch/Atoms/Card'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Accordion from 'Clutch/Molecules/Accordion'
import Form from 'Clutch/Atoms/Form'
import Button from 'Clutch/Molecules/Button'
import Skeleton from 'Clutch/Atoms/Skeleton'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import { validateZip } from 'Clutch/Utilities/Location/Location';
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import getClientSideQueryParam from "Clutch/Utilities/UrlUtilities/getClientSideQueryParam";
import { useProductPageStore } from '../../../contexts/ProductPageStore'
import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";

import styles from './shipping.module.scss'
import styled from 'styled-components'

const Shipping = ({ skuVariant, availability, backgroundColor, isPurchaseable }) => {

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  });

  const getFreeShippingAndAvailability = useProductPageStore(x => x.getFreeShippingAndAvailability);
  const freeShippingAndAvailability = useProductPageStore(x => x.freeShippingAndAvailability);
  const loadingFreeShippingAndAvailability = useProductPageStore(x => x.loadingFreeShippingAndAvailability);
  const shippingEstimate = useProductPageStore(x => x.shippingEstimate);
  const loadingShippingEstimate = useProductPageStore(x => x.loadingShippingEstimate);
  const setShippingInView = useProductPageStore(x => x.setShippingInView);
  const showInStorePickupDrawer = useProductPageStore(x => x.showInStorePickupDrawer);

  const userZipCode = useUserStore((x) => x.context.zipCode);
  const updateUserZipCode = useUserStore((x) => x.updateUserZipCode);

  useEffect(() => {
    if (inView && isPurchaseable) {
      setShippingInView(true)
      getFreeShippingAndAvailability(skuVariant)
    }
  }, [inView, isPurchaseable])

  const [storeCodeFromURL, setStoreCodeFromURL] = useState();
  const [overrideStoreCodeFromURL, setOverrideStoreCodeFromURL] = useState(false);
  const [zipCode, setZipCode] = useState();
  const [zipError, setZipError] = useState(null);
  const [inStoreInfo, setInStoreInfo] = useState({});
  const [shippingText, setShippingText] = useState("");
  const allShipsFree = useProductPageStore(x => x.allShipsFree);
  const brand = useProductPageStore(x => x.brand);


  const showFreeShippingFF = useLazyFeatureFlag("Show_Free_Shipping_PDP", allShipsFree);

  useEffect(() => {
    setStoreCodeFromURL(getClientSideQueryParam("store_code")?.toLowerCase())
  }, [inView])

  const showIspInfo = freeShippingAndAvailability &&
  (freeShippingAndAvailability.warehouseAvailability || []).length &&
  availability !== 'Factory Shipped' &&
  availability !== 'On Backorder'

  useEffect(() => {
    if(showIspInfo){ 
      setInStoreInfo(getInStoreInfo())
    }
  }, [overrideStoreCodeFromURL, storeCodeFromURL, userZipCode, showIspInfo, freeShippingAndAvailability])

  useEffect(() => {
    if (shippingEstimate){
      if (showFreeShippingFF && availability === 'Factory Shipped' && shippingEstimate?.freeShippingQuoteAvailable){
        setShippingText("Ships Free From " + brand.brandName)
      } else if (showFreeShippingFF && shippingEstimate?.freeShippingQuoteAvailable){
        setShippingText("Ships Free")
      } else if (availability === 'Factory Shipped') {
        setShippingText("Ships From " + brand.brandName)
      } else if (shippingEstimate.shipsToday) {
        setShippingText("Ships Today");
      } else {
        setShippingText("Shipping Estimate");
      }
    }
  }, [shippingEstimate, showFreeShippingFF])

  const submitZip = (e) => {
    e.preventDefault();
    if(validateZip(`${zipCode}`)){
      setOverrideStoreCodeFromURL(true)
      updateUserZipCode(`${zipCode}`);
      setZipError(null);
    } else{
      setZipError("Please enter a 5 digit ZIP code");
    }
  }

  const inStoreInfoLNK = {
      text: 'Available in Lincoln',
      intent: 'action',
      dataTestId: 'available_in_lincoln',
      icon: "check"
  }
  const inStoreInfoPHX = {
      text: 'Available in Phoenix',
      intent: 'action',
      dataTestId: 'available_in_phoenix',
      icon: "check"
  }
  const notInStoreInfo = {
      text: 'Unavailable for Store Pickup',
      tone: 'superfluous',
      dataTestId: 'isp_unavailable',
      icon: "unavailable"
  }

  const getInStoreInfo = () => {
    if(storeCodeFromURL && !overrideStoreCodeFromURL){
      if (storeCodeFromURL === "smi-phx" && checkInStockEligibility('SMI-PHX')){
        return inStoreInfoPHX
      } else if (storeCodeFromURL === "smi-lnk" && checkInStockEligibility('SMI-LNK')){
        return inStoreInfoLNK
      } else if (checkInStockEligibility('SMI-LNK')) { //case where store code was phx but wasn't in stock there
        return inStoreInfoLNK
      } else if (checkInStockEligibility('SMI-PHX')) { //case where store code was lnk but wasn't in stock there
        return inStoreInfoPHX
      } else {
        return notInStoreInfo
      }
    } else {
      if (!userZipCode || userZipCode < 83200){ //defaults to LNK location
        if (checkInStockEligibility('SMI-LNK')){
          return inStoreInfoLNK
        } else if (checkInStockEligibility('SMI-PHX')) {
          return inStoreInfoPHX
        } else {
          return notInStoreInfo
        }
      } else { // default to PHX location
        if (checkInStockEligibility('SMI-PHX')){
          return inStoreInfoPHX
        } else if (checkInStockEligibility('SMI-LNK')) {
          return inStoreInfoLNK
        } else {
          return notInStoreInfo
          }
        }
      }
    } 

  const checkInStockEligibility = (location) => {
    const inStock = freeShippingAndAvailability && (freeShippingAndAvailability.warehouseAvailability || []).some(warehouse =>
      warehouse.warehouseId === location && warehouse.availability === 'InStock');
    return inStock
  } 

  if (!isPurchaseable)
    return false

  if (availability !== 'In Stock' && availability !== "Factory Shipped")
    return false
  
  return (
    <ErrorBoundary>
      <div className={styles.shipping_card_spacer} ref={ref} />
      {showIspInfo || (shippingEstimate || (loadingShippingEstimate && skuVariant)) ?
      <div className={styles.shipping_card_wrapper}>
        <Card fill>
          {loadingShippingEstimate && skuVariant ?
            <div className={styles.shipping_card}>
              <StyledAreaIcon backgroundColor={backgroundColor} className={styles.shipping_icon} />
              <span>
                <Skeleton width={100} height={12} />
                <Skeleton width={50} height={12} />
                <Skeleton width={125} height={12} />
              </span>
            </div> : false}
          {shippingEstimate && !loadingShippingEstimate && skuVariant ?
            <div className={styles.shipping_card}>
              <StyledAreaIcon backgroundColor={backgroundColor} className={styles.shipping_icon}>
                <Svg icon={'fast_and_free'} size={1.5} className={styles.shipping_icon_svg} />
              </StyledAreaIcon>
              <span className={styles.shipping_card_details}>
                <Typography font={'bold'} size={1}>
                  {shippingText}
                </Typography>
                <Typography size={0.875} inline>
                  {availability !== 'Factory Shipped' ? 'Get it by ' : 'Expected to arrive by '}
                </Typography>
                <span className={styles.short_date_string}>
                  <Typography size={0.875} font={'bold'} inline >
                    {showFreeShippingFF && shippingEstimate.freeShippingQuoteAvailable ? shippingEstimate.freeShippingQuote.getItByDateShort : shippingEstimate.getItByDateShort}
                  </Typography>
                </span>
                <span className={styles.long_date_string}>
                  <Typography size={0.875} font={'bold'} inline >
                    {showFreeShippingFF && shippingEstimate.freeShippingQuoteAvailable ? shippingEstimate.freeShippingQuote.getItByDateLong : shippingEstimate.getItByDateLong}
                  </Typography>
                </span>
                <Accordion summary={<>
                  <Typography size={0.875} inline tone={'subtle'}>
                    Ship to:
                  </Typography>
                  <TextLink size={0.875} onClick={() => { }} intent={'action'} inline>
                    {` ${shippingEstimate.postalCode}`}
                  </TextLink>
                </>}
                  fill
                  segmentAccordionName={'pdp shipping estimator'}
                  size={0.875}>
                  <div className={styles.shipping_est_form}>
                    <Form id={'pdp-shipping-estimator-form'} onSubmit={submitZip}>
                      <Form.Textbox id={'pdp-shipping-estimator-zipcode'}
                        className={styles.shipping_est_textbox}
                        label={'Zip Code'}
                        data-testid={'shipping-estimator-textbox'}
                        type={'number'}
                        min={'0'}
                        max={'99999'}
                        minLength={5}
                        maxLength={5}
                        hideSpinner
                        onChange={(e) => setZipCode(`${e.target.value}`)}
                        onKeyPress={(e) => {
                          if (e.target.value.length >= 5) {
                            e.preventDefault()
                          }
                        }} />
                      <Button
                        className={styles.shipping_est_button}
                        type={'submit'}
                        intent={'action'}
                        size={'small'}
                        text={'Update'}
                        segmentEvent={createClickedSegmentEvent('ShippingEstimateSubmit')}
                        data-testid={'submit-shipping-estimator'} />
                      <Typography size={0.875} intent={'negative'}>{zipError}</Typography>
                    </Form>
                  </div>
                </Accordion>

              </span>
            </div> : false}

          {(showIspInfo || loadingFreeShippingAndAvailability) && (shippingEstimate || (loadingShippingEstimate && skuVariant) && availability !== "Factory Shipped") ?
            <div className={styles.shipping_internal_spacer} /> : false
          }

          {showIspInfo ?
            <div className={styles.shipping_card}>
              <StyledAreaIcon backgroundColor={backgroundColor} className={styles.shipping_icon}>
                <Svg icon={'in_store_pickup'} size={1.5} className={styles.shipping_icon_svg} />
              </StyledAreaIcon>
              <span>
                <Typography size={0.875}>
                  Store Pickup
                </Typography>
                { inStoreInfo ?
                  <>
                    {inStoreInfo?.intent ?
                      <Svg icon={inStoreInfo.icon} size={1} intent={inStoreInfo.intent} />
                      :
                      <Svg icon={inStoreInfo.icon} size={1} tone={inStoreInfo.tone} />
                    }
                    <Typography size={1} font={'bold'} data-testid={inStoreInfo.dataTestId} className={styles.shipping_isp_text} >
                      {inStoreInfo.text}
                    </Typography>
                  </> 
                  :
                  false
                }
                <TextLink size={0.875} className={styles.check_other_locations} href={'#'} intent={'action'} chevron={'right'} onClick={() => showInStorePickupDrawer()}>
                  Check other locations
                </TextLink>
              </span>
            </div> : 
              loadingFreeShippingAndAvailability && availability !== "Factory Shipped" ?  
                <div className={styles.shipping_card}>
                  <StyledAreaIcon backgroundColor={backgroundColor} className={styles.shipping_icon} />
                  <span>
                    <Skeleton width={100} height={13} />
                    <Skeleton width={160} height={16} />
                    <Skeleton width={170} height={13} />
                  </span>
                </div>
                : 
                false
            }
        </Card> </div> : false}
    </ErrorBoundary >
  )
}

const StyledAreaIcon = styled.div`
    background-color: ${props => props.backgroundColor};
    padding: 0.5rem;
    border-radius: 100%;
    margin-right: 1rem;
`

export default Shipping